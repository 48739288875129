<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { required, sameAs} from "vuelidate/lib/validators";
import Master from "@/apis/Master";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "CHANGE PASSWORD",

      changePassword: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      submitted: false,
      successMessage: "",
      errorMessage: "",
      errorCodeMessage: "",
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.categoryData.length;
    // }
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.categoryData.length;

    // Master.categoryList({
    //   trash: 0
    // }).then((res) => {
    //   console.log(res)
    //   this.categoryData = res.data.data;
    // })
  },
  validations: {
    changePassword: {
      oldPassword: {required},
      newPassword: {required},
      confirmPassword: {required, sameAsPassword: sameAs("newPassword")}
    }
  },
  methods: {
    /**
     * Edit Country Modal
     */
    changePasswordSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        Master.changePassword({
          adminId:1,
          oldPassword: this.changePassword.oldPassword,
          newPassword: this.changePassword.newPassword,
          confirmPassword: this.changePassword.confirmPassword
        }).then((res) => {
          console.log(res)
          this.successMessage = res.data.message;
          this.changePassword.oldPassword = "";
          this.changePassword.newPassword = "";
          this.changePassword.confirmPassword = "";
        }).catch((err) => {
          this.errorMessage = err.response.data.errors;
        })

      }
    }
  }
}
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-4"></div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body ">


            <b-alert show dismissible variant="success" v-if="successMessage">
              <i class="mdi mdi-check-all mr-2"></i>{{successMessage}}
            </b-alert>
            <b-alert show dismissible variant="danger" v-if="errorMessage">
              <i class="mdi mdi-block-helper mr-2"></i>{{ errorMessage }}
            </b-alert>
            <b-alert show dismissible variant="danger" v-if="errorCodeMessage">
              <i class="mdi mdi-block-helper mr-2"></i>{{ errorCodeMessage }}
            </b-alert>
            <b-tabs nav-class="nav-tabs-custom font-18">
              <form class="form-horizontal"  @submit.prevent="changePasswordSubmit">
                <div class="form-group auth-form-group-custom mb-4">
<!--                  <i class="ri-lock-2-line auti-custom-input-icon"></i>-->
                  <span @click="showOldPassword = !showOldPassword"><i class="mdi  auti-custom-input-icon" :class="[showOldPassword ? 'mdi-eye' : 'mdi-eye-off']" ></i></span>

                  <label for="username">OLD PASSWORD</label>
                  <input
                      v-model="changePassword.oldPassword"
                      v-bind:type="[showOldPassword ? 'text' : 'password']"
                      class="form-control"
                      id="username"
                      :class="{ 'is-invalid': submitted && $v.changePassword.oldPassword.$error }"
                      placeholder="ENTER OLD PASSWORD"
                  />

                  <div
                      v-if="submitted && !$v.changePassword.oldPassword.required"
                      class="invalid-feedback"
                  >OLD PASSWORD IS REQUIRED</div>

                </div>


                <div class="form-group auth-form-group-custom mb-4" >
<!--                  <i class="ri-lock-2-line auti-custom-input-icon"></i>-->
                  <span @click="showNewPassword = !showNewPassword"> <i class="mdi  auti-custom-input-icon" :class="[showNewPassword ? 'mdi-eye' : 'mdi-eye-off']" ></i></span>

                  <label for="useremail">NEW PASSWORD</label>
                  <input
                      v-model="changePassword.newPassword"
                      v-bind:type="[showNewPassword ? 'text' : 'password']"
                      class="form-control"
                      id="useremail"
                      placeholder="ENTER NEW PASSWORD"
                      :class="{ 'is-invalid': submitted && $v.changePassword.newPassword.$error }"
                  />
                  <div v-if="submitted && $v.changePassword.newPassword.$error" class="invalid-feedback">
                    <span v-if="!$v.changePassword.newPassword.required">NEW PASSWORD IS REQUIRED</span>
                  </div>
                </div>

                <div class="form-group auth-form-group-custom mb-4">
<!--                  <i class="ri-lock-2-line auti-custom-input-icon"></i>-->
                  <span @click="showConfirmPassword = !showConfirmPassword"><i class="mdi  auti-custom-input-icon" :class="[showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off']" ></i></span>

                  <label for="userpassword">CONFIRM PASSWORD</label>
                  <input
                      v-model="changePassword.confirmPassword"
                      v-bind:type="[showConfirmPassword ? 'text' : 'password']"
                      class="form-control"
                      id="userpassword"
                      placeholder="ENTER CONFIRM PASSWORD"
                      :class="{ 'is-invalid': submitted && $v.changePassword.confirmPassword.$error }"
                  />
                  <div v-if="submitted && $v.changePassword.confirmPassword.$error" class="invalid-feedback">
                    <span v-if="!$v.changePassword.confirmPassword.required">CONFIRM PASSWORD IS REQUIRED</span>
                    <span
                        v-else-if="!$v.changePassword.confirmPassword.sameAsPassword"
                    >CONFIRM PASSWORD SHOULD BE THE SAME AS NEW PASSWORD</span>

                    <!--                    <span v-if="!$v.changePassword.confirmPassword.sameAsPassword">Confirm Password is required.</span>-->
                  </div>

                </div>

                <div class="text-center">
                  <button
                      class="btn btn-primary w-md waves-effect waves-light"
                      type="submit"
                  >Update</button>
                </div>


              </form>


              <!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
      </div>
      <div class="col-lg-4"></div>
    </div>
    <!-- Modal Add -->

    <!-- end modal -->


    <!-- Modal Edit -->

    <!-- end modal -->
  </Layout>
</template>